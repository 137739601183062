<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Getting Started</h4>
      <p>
        This section enables you to begin sending sms messages from our
        platform. You can find more information at our
        <a href="https://developer.tradesk.co.ke" target="_blank"
          >developer portal.</a
        >The information below shows how to use our online API. To send messages
        directly using our web portal,
        <router-link :to="{ name: 'app.bulk-sms.send' }"
          >click here</router-link
        >
      </p>
      <div class="mt-4">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <router-link :to="{ query: { show: 'php' }}" :class="`nav-link ${$route.query.show == 'php' || ! $route.query.show ? 'active' : ''}`">PHP</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ query: { show: 'laravel' }}" :class="`nav-link ${$route.query.show == 'laravel' ? 'active' : ''}`">Laravel</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ query: { show: 'nodejs' }}" :class="`nav-link ${$route.query.show == 'nodejs' ? 'active' : ''}`">Node JS</router-link>
          </li>
        </ul>
        <div class="tab-content">
          <div id="php" :class="`tab-pane container ${$route.query.show == 'php' || !$route.query.show ? 'active' : 'fade'}`">
            <div class="step">
              <p>Install the SDK with composer</p>
<pre class="prettyprint" lang-bsh>$ composer require futurefast/tradesk</pre>
            </div>
            <div class="step">
              <p>Import the SDK where you want to send bulk sms</p>
<pre class="prettyprint">
<code class="lang-javascript">
require __DIR__ . '/vendor/autoload.php'; <br>
<br>
$sms = \FutureFast\Tradesk\SMS::config([ <br>
  'app_key' => '5dc2c1a34b476', <br>
  'app_secret' => 'VIYq8Ydqza4AyveVQXqK' <br>
]);<br>
<br>
$result = $sms->to('254712345678')->message('hello')->send();<br>
</code>
</pre>
            </div>
            <div class="step">
              <p>
                See our <a href="https://developer.tradesk.co.ke" target="_blank">developer guide</a> for more
                details
              </p>
            </div>
          </div>
          <div id="laravel" :class="`tab-pane container ${$route.query.show == 'laravel' ? 'active' : 'fade'}`">
            <div class="step">
              <p>Install the SDK with composer</p>
              <pre class="prettyprint" lang-bsh>
 $ composer require futurefast/tradesk</pre
              >
            </div>
            <div class="step">
              <p>Publish the configuration file</p>
              <pre class="prettyprint" lang-bsh>
 $ php artisan vendor:publish</pre
              >
            </div>
            <div class="step">
              <p>Att to your .env file</p>
              <pre class="prettyprint">
<code>
  FUTUREFAST_SMS_APP_ID={{ $route.params.id }} <br>
  FUTUREFAST_SMS_APP_SECRET=your-app-secret<br>
</code>
                    </pre>
            </div>
            <div class="step">
              <p>Import SMS in your controller to send sms</p>
              <pre class="prettyprint">
<code class="lang-php">
  use App\Http\Controllers\Controller; <br>
  use Illuminate\Http\Request; <br>
  use SMS; <br>
<br>
  class YourController extends Controller<br>
  { <br>
    public function sendSms() <br>
    { <br>
      $result = SMS::ready()->message('hello')->to('0712345678')->send() <br>
    } <br>
  } <br>
</code>
                    </pre>
            </div>
            <div class="step">
              <p>
                See our <a href="" target="_blank">developer guide</a> for more
                details
              </p>
            </div>
          </div>
          <div id="nodejs" :class="`tab-pane container ${$route.query.show == 'nodejs' ? 'active' : 'fade'}`">
            <div class="alert alert-info alert-with-border">
              <i class="lni-bullhorn mr-3"></i> Nodejs SDK coming soon
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (document.getElementById("code-prettify")) return; // was already loaded
    var scriptTag = document.createElement("script");
    scriptTag.src =
      "https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js";
    scriptTag.id = "code-prettify";
    document.getElementsByTagName("head")[0].appendChild(scriptTag);
  }
};
</script>

<style lang="scss">
.instructions {
  .tab-content {
    .tab-pane {
      padding: 1rem;

      pre {
        span {
          line-height: 1px;
        }
      }
    }
  }
}
/* desert scheme ported from vim to google prettify */
pre.prettyprint {
  display: block;
  background-color: #333;
}
pre .nocode {
  background-color: none;
  color: #000;
}
pre .str {
  color: #ffa0a0;
} /* string  - pink */
pre .kwd {
  color: #f0e68c;
  font-weight: bold;
}
pre .com {
  color: #87ceeb;
} /* comment - skyblue */
pre .typ {
  color: #98fb98;
} /* type    - lightgreen */
pre .lit {
  color: #cd5c5c;
} /* literal - darkred */
pre .pun {
  color: #fff;
} /* punctuation */
pre .pln {
  color: #fff;
} /* plaintext */
pre .tag {
  color: #f0e68c;
  font-weight: bold;
} /* html/xml tag    - lightyellow */
pre .atn {
  color: #bdb76b;
  font-weight: bold;
} /* attribute name  - khaki */
pre .atv {
  color: #ffa0a0;
} /* attribute value - pink */
pre .dec {
  color: #98fb98;
} /* decimal         - lightgreen */

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
  color: #aeaeae;
} /* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none;
}
/* Alternate shading for lines */
li.L1,
li.L3,
li.L5,
li.L7,
li.L9 {
}

@media print {
  pre.prettyprint {
    background-color: none;
  }
  pre .str,
  code .str {
    color: #060;
  }
  pre .kwd,
  code .kwd {
    color: #006;
    font-weight: bold;
  }
  pre .com,
  code .com {
    color: #600;
    font-style: italic;
  }
  pre .typ,
  code .typ {
    color: #404;
    font-weight: bold;
  }
  pre .lit,
  code .lit {
    color: #044;
  }
  pre .pun,
  code .pun {
    color: #440;
  }
  pre .pln,
  code .pln {
    color: #000;
  }
  pre .tag,
  code .tag {
    color: #006;
    font-weight: bold;
  }
  pre .atn,
  code .atn {
    color: #404;
  }
  pre .atv,
  code .atv {
    color: #060;
  }
}
</style>
